import { useState } from 'react';
import { urls, openUrl } from '../util/const/urlConst';
import { Countlykeys } from '../util/const/countlyKey';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import DialogCookie from './DialogCookie'
import DialogPersonal from "./DialogPersonal";
import DialogTerm from "./DialogTerm";
import { ModalOption } from '../util/const/const'
import {useDispatch} from "react-redux";

export default () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [personalDialogVisible, setPersonalDialogVisiblee] = useState(false)
    const [centerDialogVisible, setCenterDialogVisible] = useState(false)
    const [termDialogVisible, setTermDialogVisible] = useState(false)
    const handleClick = (sectionId:any) => {
        dispatch({ type: 'setToggleHowToBuy', data: false })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({type: 'setLicensePage', data: false})
        sessionStorage.setItem("togglePledgeDetail", "false");
        sessionStorage.setItem("toggleHowToBuy", "false");
        sessionStorage.setItem('pageName', 'home')
        sessionStorage.setItem("toggleLicensePage", "false");
        setTimeout(() => {
            goToBlock(sectionId);
        }, 0)
    }
    const goToBlock = (sectionId:any) => {
        const targetElement = document.getElementById(sectionId);
        const fixedElementHeight = window.innerWidth <= 1000 ? 80 : 104;
        if (targetElement) {
            const rect = targetElement.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + rect.top - fixedElementHeight,
                behavior: 'smooth',
            });
        }
    }
    const goPcHowToBuy = () => {
        dispatch({ type: 'setToggleHowToBuy', data: true })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({type: 'setLicensePage', data: false})
        sessionStorage.setItem("togglePledgeDetail", "false");
        sessionStorage.setItem("toggleHowToBuy", "true");
        sessionStorage.setItem("toggleLicensePage", "false");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const goToLicense = () => {
        dispatch({type: 'setToggleHowToBuy', data: false})
        dispatch({type: 'setTogglePledgeDetail', data: false})
        dispatch({type: 'setLicensePage', data: true})
        sessionStorage.setItem("togglePledgeDetail", "false");
        sessionStorage.setItem("toggleHowToBuy", "false");
        sessionStorage.setItem("toggleLicensePage", "true");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
   return(<>
        <div className="bottombar">
            <div className="showNav">
                <img alt="project logo" style={{width: "136px", height: "auto"}}
                     src="img/logo.png"/>
                    <p className="navContainer">
                        <a onClick={() => {handleClick('home')}}>{ t('topBar.index') }</a>
                        <a onClick={() => {handleClick('preSale')}}>{ t('topBar.welfare') }</a>
                        <a onClick={() => {handleClick('operationContainer')}}>{ t('topBar.methods') }</a>
                        <a onClick={() => {handleClick('economicToken')}}>{t('topBar.economic') }</a>
                        <a onClick={() => {handleClick('pledgeCoin')}}>{ t('topBar.reward') }</a>
                        <a onClick={goPcHowToBuy}>{ t('topBar.howBuy') }</a>
                        <a onClick={goToLicense}>{t('topBar.license')}</a>
                        <a onClick={() => {handleClick('commonProblemContainer')}}>{ t('topBar.qa') }</a>
                    </p>
            </div>
            <div className="showMobileLogo">
                <img alt="project logo" style={{width: "90px", height: "auto"}} src="img/logo.png"/>
            </div>
            <p className="diver"></p>
            <p className="payforTypeTitle">{ t('bottomBar.text1') }</p>
            <div className="displayItem">
                <div className="payforType">
                    <img onClick={() => {openUrl(urls.tgbTgUrl, Countlykeys.tg_click)}} src="img/telegram-icon.webp" />
                    <img onClick={() => {openUrl(urls.twitter, Countlykeys.twitter_click)}} src="img/twitter-icon.webp" />
                    <img onClick={() => {openUrl(urls.auditUrl, Countlykeys.gameTg_click)}} src="img/adLogo.webp" />
                    <img onClick={goToLicense} src="img/gamingcuracao-sm.webp" />
                </div>
                <p className="diverMobile"></p>
                <div className="copyright">
                    @ 2024 TG BET All Rights Reserved
                </div>
                <div className="other">
                    <span onClick={() => setPersonalDialogVisiblee(true)}>{ t('bottomBar.text2') }</span>
                    <span onClick={() => {setTermDialogVisible(true)}}>{ t('bottomBar.text3') }</span>
                    <span onClick={() => {setCenterDialogVisible(true)}}>Cookie</span>
                </div>
            </div>
        </div>
       <Modal title={'Cookies'} footer={null} visible={centerDialogVisible} className={'resetTipsModal'}  {...ModalOption} onCancel={() => {setCenterDialogVisible(false)}}>
            <DialogCookie/>
       </Modal>
       <Modal title={'Terms Of Service'} footer={null} visible={termDialogVisible} className={'resetTipsModal'}  {...ModalOption} onCancel={() => {setTermDialogVisible(false)}}>
           <DialogTerm/>
       </Modal>
       <Modal title={'Privacy Policy'} footer={null} visible={personalDialogVisible} className={'resetTipsModal'}  {...ModalOption} onCancel={() => {setPersonalDialogVisiblee(false)}}>
           <DialogPersonal/>
       </Modal>
   </>)
}
