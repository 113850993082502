import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Row, Col} from 'antd'
import Line from './Line'
import '../assets/style/EconomicToken.scss'
export default () => {
    const { t } = useTranslation();
    const [gutter, setGutter] = useState(30);
    const  economicTokenList = [
        { title: t('economicToken.text6'), percentage: '40%', desc: t('economicToken.text7'), color: '#C5AC79' },
        { title: t('economicToken.text4'), percentage: '20%', desc: t('economicToken.text5'), color: '#FFFFFF99' },
        { title: t('economicToken.text2'), percentage: '10%', desc: t('economicToken.text3'), color: '#64CF3C' },
        { title: t('economicToken.text8'), percentage: '10%', desc: t('economicToken.text9'), color: '#F47F30' },
        { title: t('economicToken.text10'), percentage: '5%', desc: t('economicToken.text11'), color: '#CF3C7A' },
        { title: t('economicToken.text12'), percentage: '5%', desc: t('economicToken.text13'), color: '#3CA3CF' },
        { title: t('economicToken.text14'), percentage: '5%', desc: t('economicToken.text15'), color: '#BACF3C' },
        { title: t('economicToken.text16'), percentage: '5%', desc: t('economicToken.text17'), color: '#8E3CCF' },
    ]
    useEffect(() => {
        // @ts-ignore
        const screenWidth = window.innerWidth || document.documentElement.clientWidth
        if (screenWidth > 900) {
            setGutter(30)
        } else {
           setGutter(0)
        }
    }, [])
    return(
        <div className="economicToken" id="economicToken">
            <div className="economicTokenLeft">
                <div className="economicTokenLogo">
                    { t('blockTitle.coin') }
                    <Line></Line>
                </div>
            </div>
            <Row style={{width: "100%"}} gutter={gutter}>
            <Col xs={24} sm={24} lg={12}>
            <div className="desc">
                { t('economicToken.text1') }
            </div>
                {
                    economicTokenList.map((token:any, index:any) =>
                        <div key={index} className="economicTokenItem">
                            <div className="leftRadius" style={{ background: token.color }}></div>
                            <div className="economicTokenDetail">
                                <p className="economicTokenDetailTitle">
                                <span className="tokenPercentage" style={{ color: token.color }}>{
                                    token.percentage}</span>{ token.title }
                                </p>
                                 <p className="economicTokenDetailDesc">{ token.desc }</p>
                            </div>
                        </div>
                    )
                }
        </Col>
            <Col lg={12} style={{display: "flex", alignItems: "center"}}>
                <img className="economicTokenDetailImg" src="img/Group.png" alt='economicTokenDetailImg'/>
           </Col>
        </Row>
        </div>
    )
}
