import { useTranslation } from 'react-i18next';
import Line from './Line'
import '../assets/style/WeGame.scss'
export default () => {
    const { t } = useTranslation();

    return(
        <div className="weGameContainer">
            <div className="weGameDesc">
                <div className="weGameLogo">{ t('blockTitle.wg1') } <Line /></div>

                <div className="desc">{ t('webGame.text1') }</div>
                <div className="title">{ t('webGame.text2') }</div>
                <div className="gameDesc">{ t('webGame.text3') }</div>
                <div className="title">{ t('webGame.text4') }</div>
                <div className="gameDesc">{ t('webGame.text5') }</div>
                <div className="title">{ t('webGame.text6') }</div>
                <div className="gameDesc">{ t('webGame.text7') }</div>
            </div>
            <div className="gameImg">
                <img className="gamePng" src="img/amatic.png" alt='gamePng'/>
                <img className="gamePng" src="img/evolution.png" alt='gamePng'/>
                <img className="gamePng" src="img/hacksaw.png" alt='gamePng'/>
                <img className="gamePng" src="img/netent.png" alt='gamePng'/>
                <img className="gamePng" src="img/playngo.png" alt='gamePng'/>
                <img className="gamePng" src="img/redtiger.png" alt='gamePng'/>
                <img className="gamePng" src="img/pushgame.png" alt='gamePng'/>
                <img className="gamePng" src="img/nba.png" alt='gamePng'/>
                <img className="gamePng" src="img/ufc.png" alt='gamePng'/>
            </div>
        </div>
    )
}
