import { urls, openUrl } from '../util/const/urlConst';
import { Countlykeys } from '../util/const/countlyKey';
import { useTranslation } from 'react-i18next';
import Line from './Line'
import '../assets/style/NoKyc.scss'


export default () => {
    const { t } = useTranslation();
    const steps = [
        { title: t('noKyc.text4'), desc: t('noKyc.text5') },
        { title: t('noKyc.text6'), desc: t('noKyc.text7') },
        { title: t('noKyc.text8'), desc: t('noKyc.text9') }
    ]
    return(
        <div className="noKyc" id="noKyc">
            <div className="noKycLogo">{ t('blockTitle.nokyc1') } <Line /></div>
            <div className="desc">
                { t('noKyc.text1') }
            </div>
            <div className="btnContainer">
                <div className="open" onClick={() => {openUrl(urls.tgbTgUrl,Countlykeys.tg_click)}}>{ t('noKyc.text2') } </div>
            <div className="downLoad" onClick={() => {openUrl(urls.tgDownloadUrl,Countlykeys.tgDownload_click)}}>{ t('noKyc.text3') }</div>
            </div>
            <div className="step">
                {
                    steps.map((item:any, index:any) =>
                        <div key={index} className="stepItem">
                            <p className="stepTitle">{ item['title'] }</p>
                            <p className="stepDesc">{ item.desc }</p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
