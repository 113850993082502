import {useState, useRef} from 'react';
import { urls, openUrl } from '../util/const/urlConst';
import { Countlykeys } from '../util/const/countlyKey';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'antd';
import '../assets/style/WhyPopular.scss'
import Line from './Line'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';


const WhyPopular =  () => {
    const myRef = useRef(null);
    const { t } = useTranslation();
    const [currentIndex, setcurrentIndex] = useState(0);
    const data =  [{
        desc:t('whyPopular.text1'),
        topImg: null,
        rightImg: "img/play1.png",
        btnText:t('whyPopular.text2')
    }, {
        desc:t('whyPopular.text3'),
        topImg: null,
        rightImg: "img/play2.png",
        btnText:t('whyPopular.text4')
    }, {
        desc:t('whyPopular.text5'),
        topImg: null,
        rightImg: "img/play3.png",
        btnText:t('whyPopular.text4')
    }]
    const onChange = (currentSlide:number) => {
        setcurrentIndex(currentSlide)
    }
    return(
        <div className="whyPopularContainer">
            <div className="btnLeft" onClick={() => { // @ts-ignore
                myRef.current.prev() }}><LeftOutlined /></div>
            <div className="btnRight" onClick={() => { // @ts-ignore
                myRef.current.next() }}><RightOutlined/></div>
            <Carousel afterChange={onChange} autoplay ref={myRef}>
                {
                    data.map((item:any, index:any) =><div key={index} className="loopitem">
                        <div className="whyPopularDesc">
                            {index === 0 ? <div className="whyPopularLogo">{ t('blockTitle.wp1')}！ <Line  /></div> : ""}
                            {index === 1 ? <div className="whyPopularLogo">{ t('blockTitle.wp2')}
                                <Line/></div> : ""}
                            { index === 2 ? <div className="whyPopularLogo">{ t('blockTitle.wp4')} <Line /></div>: "" }
                            <div className="whyPopularDetailMobileContainer">
                                {index === 0 ? <div className="whyPopularMobileLogo">{ t('blockTitle.wp1')}  </div>: ""}
                                {index === 1 ? <div className="whyPopularMobileLogo">{ t('blockTitle.wp2')} </div> : ""}
                                {index === 2 ? <div className="whyPopularMobileLogo">{ t('blockTitle.wp4')}  </div>: "" }
                                <div style={{position: "relative"}}>
                                    <img className="whyPopularDetailMobile" src={item.rightImg} alt="whyPopularDetailMobile"/>
                                    <div className="progress-bar">
                                        {
                                            data.map((citem:any, cindex:any) =>
                                                <div key={cindex} className="progress-wrapper">
                                                    <div className={classnames({'progress-dot': true, 'active': cindex === currentIndex})}></div>
                                                    <div className="progress-segment"></div>
                                                    <div className={classnames({"progress-segment-top": true,'progress-segment-action': cindex === currentIndex })}
                                                    style={{ transition: cindex === currentIndex ? 'width 1s ease-in-out' : 'none' }}></div>
                                                </div>)
                                        }
                                    </div>
                                </div>
                             </div>
                            <div className="desc">{ item.desc }</div>
                            <div className="play" onClick={() => {openUrl(urls.gameTgUrl,Countlykeys.gameTg_click)}}>{ item.btnText }</div>
                            </div>
                        <div style={{position: "relative"}} className="whyPopularDetailContainer">
                        <img className="whyPopularDetail" src={item.rightImg} alt="whyPopularDetail"/>
                        <div className="progress-bar">
                            {data.map((sI:any, sIndex:number) => <div key={sIndex} className="progress-wrapper">
                                <div className={classnames({'progress-dot': true, 'active': sIndex === currentIndex})}></div>
                                <div className="progress-segment"></div>
                                <div className={classnames({"progress-segment-top": true,'progress-segment-action': sIndex === currentIndex})}
                                     style={{ transition: sIndex === currentIndex ? 'width 1s ease-in-out' : 'none' }}></div>
                            </div>)}
                        </div>
                    </div>
                    </div>)
                }
            </Carousel>
        </div>
    )
}
export default WhyPopular;
