import '../assets/style/Line.css'
import {useTranslation} from "react-i18next";
import {Row, Col} from 'antd'
import Line from './Line'
import '../assets/style/PreSale.scss'
// eslint-disable-next-line
export default () => {
    const { t } = useTranslation();
    const preSaleDescList = [
        {
            title: t("preSale.text2"),
            desc: t("preSale.text3"),
            src: "img/specialRewards.png",
        },
        {
            title: t("preSale.text4"),
            desc: t("preSale.text5"),
            src: "img/preferentialPrice.png",
        },
        {
            title: t("preSale.text6"),
            desc: t("preSale.text7"),
            src: "img/plutocrat.png",
        },
        {
            title: t("preSale.text8"),
            desc: t("preSale.text9"),
            src: "img/money.png",
        },
    ]

    return(
        <div className="preSale" id="preSale">
            <div className="preSaleTitleImg">
                { t("blockTitle.preTxt1") }
                <Line/>
            </div>

            <div className="desc">{ t("preSale.text1") }</div>
            <Row gutter={40}>
                {
                    preSaleDescList.map((item:any, index:any) =>
                        <Col xs={24} sm={24} md={6} key={index}
                             style={{paddingBottom: "32767px !important",
                                 marginBottom: "-32767px !important" }}>
                            <div className="descItem">
                                <img className="preSaleImg" src={item.src} alt='preSale'/>
                                <div>
                                    <p className="title">{ item.title }</p>
                                    <p className="subtitle">{ item.desc }</p>
                                </div>
                            </div>
                        </Col>)
                }

             </Row>
        </div>
    )
}
