import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import Line from './Line'
import '../assets/style/CommonProblem.scss'

export default () => {
    const { t } = useTranslation();
    const { Panel } = Collapse;
    const commonProblems = [
        { id: 1, name: t('commonProblem.text1'), title: t('commonProblem.text1'), content: t('commonProblem.text2') },
            { id: 2, name: t('commonProblem.text3'), title: t('commonProblem.text3'), content: t('commonProblem.text4') },
            { id: 3, name: t('commonProblem.text5'), title: t('commonProblem.text5'), content: t('commonProblem.text6') },
            { id: 4, name: t('commonProblem.text7'), title: t('commonProblem.text7'), content: t('commonProblem.text8') },
            { id: 5, name: t('commonProblem.text9'), title: t('commonProblem.text9'), content: t('commonProblem.text10') },
        ]
    return(
        <div className="commonProblemContainer" id="commonProblemContainer">
            <div className="commonProblemLogo">
                {t("blockTitle.qa") }
                <Line/>
            </div>
            <Collapse defaultActiveKey={['1']}>
                {
                    commonProblems.map((item:any) => <Panel header={item.name} key={item.id}>
                        <p>{item.content}</p>
                    </Panel>)
                }
            </Collapse>
        </div>
    )
}
