import { urls, openUrl } from '../util/const/urlConst';
import { Countlykeys } from '../util/const/countlyKey';
import { useTranslation } from 'react-i18next';
import Line from './Line'
import '../assets/style/SafeInstructions.scss'
export default () => {
    const { t } = useTranslation();

    return(
        <div className="safeContainer">
            <div className="safeDesc">
                <div className="safeLogoImg">
                    { t('blockTitle.safe1') }
                    <Line></Line>
                </div>
                <div className="desc">{ t('safeInstructions.text1') }</div>
                <div className="safeItem">
                    <img style={{width: "18px", height: "24px"}} src="img/safeItem.png" alt=''/>
                    <p className="safeTitle">{ t('safeInstructions.text2') }</p>
                </div>
                <div className="safeItem">
                    <img style={{width: "18px", height: "24px"}} src="img/safeItem.png" alt=''/>
                    <p className="safeTitle">{ t('safeInstructions.text3') }</p>
                </div>
                <div className="safeItem">
                    <img style={{width: "18px", height: "24px"}} src="img/safeItem.png" alt=''/>
                    <p className="safeTitle">{ t('safeInstructions.text4') }</p>
                </div>
                <div className="safeItem">
                    <img style={{width: "18px", height: "24px"}} src="img/safeItem.png" alt=''/>
                    <p className="safeTitle">{ t('safeInstructions.text5') }</p>
                </div>
                <div className="play" onClick={() => {openUrl(urls.gameTgUrl,Countlykeys.gameTg_click)}}>{ t('safeInstructions.text6') }</div>
            <img className="webImage" src="img/webImage.png" alt=''/>
        </div>
    <img className="webImg" style={{height: "499px",marginRight: "-58px"}} src="img/safeLogo.png" />
    </div>
    )
}
