import { urls, openUrl } from '../util/const/urlConst';
import { Countlykeys } from '../util/const/countlyKey';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import Line from './Line';
import '../assets/style/WhiteBook.scss';
const WhiteBook =  () => {
    const { t } = useTranslation();

    return(
        <div className="whiteBookContainer">
            <Row style={{width: "100%",display: "flex",alignItems:"center"}} gutter={30}>
            <Col xs={24} sm={24}  md={24}  lg={13}>
                <div className="whiteBookDesc">
                    <div className="whiteBookLogo">{ t('blockTitle.wb') }
                        <Line/>
                    </div>
                    <img className="whiteBookImgMobile" src="img/whiteBookImg.png" alt="whiteBookImgMobile"/>
                        <div className="desc">{t('whiteBook.text1') }</div>
                        <div className="read" onClick={() => {openUrl(urls.whitePaperUrl,Countlykeys.whitePaper_click)}}>
                            { t('whiteBook.text2') }
                        </div>
                </div>
            </Col>
            <Col lg={11}>
                <img className="whiteBookImg" src="img/whiteBookImg.png" alt="whiteBookImg"/>
            </Col>
        </Row>
        </div>
    )
}
export default WhiteBook;
