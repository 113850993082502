import { useTranslation } from 'react-i18next';
import { urls, openUrl } from '../util/const/urlConst';
import { Countlykeys } from '../util/const/countlyKey';
import {useDispatch} from "react-redux";

export  default ()=> {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const goToLicense = () => {
        dispatch({ type: 'setToggleHowToBuy', data: false })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({ type: 'setLicensePage', data: true })
        sessionStorage.setItem("togglePledgeDetail", "false");
        sessionStorage.setItem("toggleHowToBuy", "false");
        sessionStorage.setItem("toggleLicensePage", "true");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return(
        <div className="actionView">
            <p className="title">{ t('actionView.text1') }</p>
            <p className="subTitle">{ t('actionView.text2') }</p>
            <p className="desc">{ t('actionView.text3') }</p>
            <div className="join" onClick={() => {openUrl(urls.tgbTgUrl,Countlykeys.tg_click)}}> <img className="joinLogo" alt="join logo" src="img/join.png"/>
            { t('actionView.text4') }
            </div>
        <div className="play" onClick={() => {openUrl(urls.gameTgUrl,Countlykeys.gameTg_click)}}> <img className="playLogo" alt="play logo" src="img/playLogo.png"/> { t('actionView.text5') }
        </div>
        <div className="check" onClick={goToLicense}><img className="checkLogo" alt="play logo" src="img/checkLogo.png"/> { t('actionView.text6') }
        </div>
        <div className="cashback">
            <p className="cashbackTitle">25%</p>
            <p className="cashbackSubTitle">{ t('actionView.text7') }</p>
            <p className="cashbackDesc">{ t('actionView.text8') }</p>
        </div>
    </div>
    )
}
