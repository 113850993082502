
import stakeABI from '../../abi/stakeAbi';
import proxyABI from '../../abi/proxyAbi';
import { erc20ABI } from "@wagmi/core";


const projectId = '3250eb9b1d71fe0b64d14d68f1778dbb'

const usdtAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7'
const usdcAddress = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
const tgbAddress = '0x9972D4FB697538fD5451dA19377D4acffC9735dD'
const stakeAddress = '0xb8D96b8129c2013C1963F152155718C1595A3d25'
const preSaleAddress = '0x414523686B12E9597c8cdB4e6379e88e11D4931F'

const MAX_ALLOWANCE = 115792089237316195423570985008687907853269984665640564039457584007913129639935n;

const getUsdtContract = () => {
    return  {
        address: usdtAddress,
        abi: erc20ABI,
    }
}

const getUsdcContract = () => {
    return  {
        address: usdcAddress,
        abi: erc20ABI,
    }
}

const getTgbContract = () => {
    return  {
        address: tgbAddress,
        abi: erc20ABI,
    }
}

const getStakeContract = () => {
    return  {
        address: stakeAddress,
        abi: stakeABI,
    }
}

const getPreSaleContract = () => {
    return  {
        address: preSaleAddress,
        abi: proxyABI,
    }
}

export const ModalOption = {
    keyboard: false,
    maskClosable: false
};

export {
    projectId,
    MAX_ALLOWANCE,
    getPreSaleContract,
    getStakeContract,
    getTgbContract,
    getUsdcContract,
    getUsdtContract,
    usdtAddress,
    usdcAddress,
    tgbAddress,
    stakeAddress,
    preSaleAddress
}
