import {Row, Col} from 'antd'
import '../assets/style/CrytoNews.scss'
export default () => {
    return(
        <Row justify="space-between" align="middle" className="cryptoNews">
            <Col xs={12} sm={8} md={4}>
            <img className="newsPng" src="img/cointelegraph.png" alt='newsPng'/>
            </Col>
            <Col xs={12} sm={8} md={4}>
            <img className="newsPng" src="img/crpttonaute.png" alt='newsPng'/>
            </Col>
            <Col xs={12} sm={8} md={4}>
            <img className="newsPng" src="img/coincierge.png" alt='newsPng'/>
            </Col>
            <Col xs={12} sm={8} md={4}>
            <img className="newsPng" src="img/keyptoszene.png" alt='newsPng'/>
            </Col>
            <Col xs={12} sm={8} md={4}>
            <img className="newsPng" src="img/coinSpeaker.png" alt='newsPng'/>
            </Col>
            <Col xs={12} sm={8} md={4}>
            <img className="newsPng" src="img/cryptonnews.png" alt='newsPng'/>
            </Col>
        </Row>
    )
}
