import '../assets/style/Line.css'
// eslint-disable-next-line
export default () => {
    return(
        <div className="line">
            <div className="line-color"></div>
            <img src="img/subtract-line.png" className="line-img" alt='line' />
            <div className="line-color"></div>
        </div>
    )
}
