import HomeForm from "./HomeForm";
import ActionView from "./ActionView";
import { Row, Col } from 'antd';
import '../assets/style/HomeCom.scss'

export  default ()=> {
    return(
        <div className="home-wrapper" id="home">
            <Row gutter={30}>
            <Col lg={3}></Col>
            <Col xs={24} sm={24} lg={8}>
                <ActionView />
                </Col>
            <Col xs={24} sm={24} lg={11}>
                <HomeForm />
                </Col>
            </Row>
        </div>
    )
}
