
import { catchError } from '../helper/countlyUtil'
import { appPublicClient } from './client'

//waitForTransactionReceipt
const waitTx = async (tx) => {
    try {
      let receipt = await appPublicClient().waitForTransactionReceipt(
        {
          confirmations: 3,
          hash: tx,
          retryCount: 2,
          retryDelay: 60_000,  
          timeout: 60_000,
          onReplaced: replacement => {
            
          }
        }
      )
      if (receipt && receipt.status === 'success') {
        return true
      } else {
        return false
      }
    } catch (error) {
        catchError(error) 
       return false
    }
}

export {waitTx}