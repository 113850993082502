import { useTranslation } from 'react-i18next';
import Line from './Line'
import '../assets/style/PledgeCoin.scss'
// eslint-disable-next-line
export default () => {
    const { t } = useTranslation();
    const steps = [
        { src: "img/pig.png", title: t('pledgeCoin.text2'), desc: t('pledgeCoin.text3') },
        { src: "img/copperCash.png", title: t('pledgeCoin.text4'), desc: t('pledgeCoin.text5') },
        { src: "img/copperCash.png", title: t('pledgeCoin.text6'), desc: t('pledgeCoin.text7') }
    ]
    return(
        <div className="pledgeCoin" id="pledgeCoin">
            <div className="pledgeCoinLogoImg">
                {t('blockTitle.plc1') }
                <Line />
            </div>
            <div className="desc">
                {t('pledgeCoin.text1') }
            </div>
            <div className="step">
                {
                    steps.map((item:any, index: any) =>
                        <div key={index} className="stepItem">
                            <img className="pledgeCoinImg" src={item.src} alt='pledgeCoin'/>
                            <p className="stepTitle">{ item.title }</p>
                            <p className="stepDesc">{ item.desc }</p>
                        </div>
                    )
                }
             </div>
        </div>
    )
}
