import { useTranslation } from 'react-i18next';
import Line from './Line'
import '../assets/style/OperationWay.scss'
export default () => {
    const { t } = useTranslation();

    return(
        <div className="operationContainer" id="operationContainer">
            <div className="operationDesc">
                <div className="operationLogoImg">
                    <span>{t('blockTitle.ow1') }</span>?
                    <Line/>
                </div>
                <div className="desc">{t('operationWay.text1')}</div>
            </div>
            <img className="operationDescImg moblie-1"  src="img/operationWayDetail.png" />
            <img className="operationDescImg pc-1"  src="img/operationWayDetai-pc.png" />
        </div>
    )
}
