import ReactEchatrs from 'echarts-for-react'
import { useState,useEffect } from 'react';
import moment from 'moment'
import { useTranslation } from 'react-i18next';

import { catchError } from '../util/helper/countlyUtil';

export default () => {
    const { t } = useTranslation();

    let tempOption = {
        tooltip: {
            backgroundColor: 'black',
            trigger: 'axis',
            axisPointer: {
                lineStyle: {
                    color: '#B38C5F'
                },
            },
            textStyle: {
                color: '#C5AC79' // 修改 Tooltip 数据颜色
            }
        },
        xAxis: {
            data: [],
            boundaryGap: false, // 设置 boundaryGap 为 false
            axisLine: {
                smooth: true, // 设置 smooth 为 true 去掉锯齿
                lineStyle: {
                    color: '#272727' // 可以设置线的颜色
                }
            },
            axisLabel: {
                textStyle: {
                    color: '#616161', // 修改 X 轴字体颜色
                    fontSize: window.innerWidth < 900 ? 10 : 14
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                textStyle: {
                    color: '#616161', // 修改 Y 轴字体颜色
                    fontSize: window.innerWidth < 900 ? 10 : 14
                },
                formatter: function(value: number) {
                    if ((value / (1000000)) > 1) {
                        return (value / (1000000)).toFixed(0) + "M";
                    } else {
                        return value;
                    }
                }
            },
            splitLine: {
                lineStyle: {
                    color: '#2D2D2D' // 修改 Y 轴上的横线颜色
                }
            }
        },
        series: [
            {
                name: t('blockTitle.zhiyazr'),
                type: 'line',
                smooth: true,  // 开启曲线
                tooltip: {
                    trigger: 'axis',
                    extraCssText: 'background-color: rgba(0, 0, 0, 0.7);', // 修改 Tooltip 的背景色
                    axisPointer: {
                        type: 'line',
                        lineStyle: {
                            color: '#C5AC79'
                        }
                    },
                },
                lineStyle: {
                    color: '#929292'
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: 'rgba(146, 146, 146, 1)' },  // 渐变开始颜色
                            { offset: 1, color: 'rgba(146, 146, 146, 0)' }    // 渐变结束颜色
                        ]
                    },
                }, // 开启面积图样式
                itemStyle: {
                    color: '#C5AC79' // 修改线上点的颜色
                },
                data: []
            }
        ],
        grid: {
            top: 30,
            bottom: 20,
            left: 42,
            right: 42
        },
    }
    const [options, setOptiton] = useState(tempOption)
    const requestTimeline = () =>  {
        try {
            let lineDada = localStorage.getItem('line')
            if (lineDada && lineDada.length > 0) {
                
                let timeLine = JSON.parse(lineDada)
                // create_at,total_stake
                let xlines:any = []
                let ylines:any = []
                timeLine.forEach((element: { create_at: any; total_stake: any; }) => {
                    let date = moment(element.create_at).format("YYYY-MM-DD");
                    xlines.push(date)
                    ylines.push(parseInt(element.total_stake))
                });
                if (xlines.length && ylines.length) {
                    tempOption.xAxis.data = xlines
                    tempOption.series[0].data = ylines
                    
                    setOptiton(tempOption)
                }
            }
        } catch (error) {
            throw error
        }
    }
    useEffect(() => {
        
        requestTimeline()
    }, [options])

    return(<>
        <div style={{height: "400px"}}>
            <ReactEchatrs key={JSON.stringify(options)}
            notMerge={true} lazyUpdate={true}
            option={options}/>
        </div>
    </>)
}
