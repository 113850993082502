import { useTranslation } from 'react-i18next';
import {Row, Col} from 'antd'
import Line from './Line'
import '../assets/style/CoinTimes.scss'
export default () => {
    const { t } = useTranslation();

    return(
        <div className="coinTimesContainer">
            <Row style={{width: "100%", display: "flex", alignItems: "center"}} gutter={30}>
            <Col xs={24} sm={24} lg={13}>
            <div className="coinTimesDesc">
                <div className="coinTimesDescLogo">
                    <span>{ t('blockTitle.ctl2') }</span>
                    <Line/>

                </div>
                <div className="coinChanceMobileImg">
                    <span>{ t('blockTitle.ctl2') }</span>
                    <Line/>
                </div>

                <div className="desc">
                    { t('coinTimes.text1') }
                </div>
            </div>
            </Col>
             <Col lg={11}>
                <img className="coinCountImage" src="img/coinCount.png" alt='coinCountImage'/>
            </Col>
        </Row>
    </div>
    )
}
