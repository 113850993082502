import { useTranslation } from 'react-i18next';
import '../assets/style/LicensePage.scss'
export default () => {
    const { t, i18n } = useTranslation();

    return(
        <div className="licenseBox">
            <div className="domainContainer">
                <h1>{t('licenseProof.text3')}</h1>
                <p>{t('licenseProof.text4')}: <span className="text-primary">142919</span>
                    <br /> {t('licenseProof.text5')}: AZuikertuintjeweg Z/N (Zuikertuin Tower) Willemstad, Curacao.</p >
                <div className="domain-img">
                    <img src="img/licenseLogo.png" alt="curacao" />
                </div>
                <p>{t('licenseProof.text6')}: 365/JAZ,<br />
                    {t('licenseProof.text7')}.</p >
                <h2>{t('licenseProof.text8')}</h2>
                <p>{t('licenseProof.text9')}</p >
                <h2>{t('licenseProof.text11')}</h2>
                <p>{t('licenseProof.text12')}<br />
                    {t('licenseProof.text13')}: HE 436300<br />
                    {t('licenseProof.text14')}: Avlonos, 1, MARIA HOUSE, 1075, Nicosia, Cyprus</p >
            </div>
        </div>
    )
}
