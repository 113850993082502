import { urls, openUrl } from '../util/const/urlConst';
import { Countlykeys } from '../util/const/countlyKey';
import { useTranslation } from 'react-i18next';
import Line from './Line'
import '../assets/style/LicenseProof.scss'
import {useDispatch} from "react-redux";
export default () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const goToLicense = () => {
        dispatch({ type: 'setToggleHowToBuy', data: false })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({ type: 'setLicensePage', data: true })
        sessionStorage.setItem("togglePledgeDetail", "false");
        sessionStorage.setItem("toggleHowToBuy", "false");
        sessionStorage.setItem("toggleLicensePage", "true");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return(
        <div className="licenseContainer">
            <div className="licenseDesc">
                <div className="licenseDescLogo">{ t('blockTitle.lsp1') } <Line /></div>
                <img className="licenseDetailMobile" src="img/licenseLogo.png" />
                    <div className="desc">{ t('licenseProof.text1') }</div>
                    <div className="check" onClick={goToLicense}>{ t('licenseProof.text2') }</div>
            </div>
            <img className="licenseDetail" src="img/licenseLogo.png" />
        </div>
    )
}
