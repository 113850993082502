import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Progress, Input, Button, Modal, message } from 'antd'
import Line from './Line'
import '../assets/style/HomeForm.scss'
import classnames from "classnames"
import copy from 'copy-to-clipboard';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { addEvent, catchError } from '../util/helper/countlyUtil';
import { Countlykeys } from '../util/const/countlyKey';
import { checkApprove, approveContract, approveContractZero } from '../util/contactUtil/approve';
import { waitTx } from '../util/contactUtil/transfaction';
import { formatUnits, parseEther, formatEther, stringToBytes } from 'viem'
import { useInterval } from 'ahooks';
import BgProgress from './BgProgress';
import {
    erc20ABI,
    getNetwork,
    switchNetwork,
} from "@wagmi/core";
import {
    useAccount,
    useDisconnect,
} from 'wagmi'
import {
    getPreSaleContract,
    getStakeContract,
    getUsdcContract,
    getUsdtContract,
    usdtAddress,
    usdcAddress,
    tgbAddress,
} from '../util/const/const'
import { appChain, appPublicClient, appWallectClient } from '../util/contactUtil/client';
import { requestCountlyConfig } from '../util/helper/countlyUtil';

const coinInfo = {
    ETH: { "contract": "", decimals: 18, name: "ETH" },
    USDT: { "contract": "", decimals: 6, name: "USDT" },
    USDC: { "contract": "", decimals: 6, name: "USDC" },
};
const fee = 0.015;

const infoDataInit = {
    tokenPrice: '' as any,
    paused: false,
    eth_to_usd: '',
    usdt_to_usd: '',
    maxTokensToBuy: '' as any,
    apy: '' as any,
    tgbAddress: '' as any
}

export default () => {
    const [messageApi, contextHolder] = message.useMessage();
    const { disconnect } = useDisconnect()
    const { openConnectModal } = useConnectModal();

    const [connected, setConnected] = useState(false)

    const account = useAccount({
        onConnect({ address, connector, isReconnected }) {

            setConnected(true)
            fetchMyBalance()
        },
        onDisconnect() {

            setConnected(false)
        },
    })

    const { t } = useTranslation();
    const [selectedCoin, setSelectedCoin] = useState(coinInfo.ETH);
    const [saleInfo, setSaleInfo] = useState({
        isSoldOut: false,
        saleAmount: 0,
        saleGoal: 1000000,
        saleAmountStr: '',
        saleProgress: 0,
        saleGoalStr: '1,000,000'
    });
    const [infoData, setInfoData] = useState(infoDataInit);
    const [timeState, setTimeState] = useState({
        day: '00',
        hour: '00',
        minute: '00',
        second: '00',
    })
    const [myBalance, setMyBalance] = useState({
        ethBalance: '0',
        tgbBalance: '0',
        usdtBalance: '0',
        usdcBalance: '0',
        tgbDeposits: "0",
        usdtAllowance: 0,
        usdcAllowance: 0
    })
    const [dialogVisible, setDialogVisible] = useState(false)
    let [leaveTime, setLeaveTime] = useState(0);
    const latestLeaveTime = useRef(leaveTime);
    const [tgbAmount, setTgbAmount] = useState('' as any);
    const [coinAmount, setCoinAmount] = useState('' as any);
    const [tipMsg, setTipMsg] = useState("");
    const [normalMsg, setNormalMsg] = useState("");
    const [walletTipMsg, setWalletTipMsg] = useState("");
    const [approve, setApprove] = useState(false)
    const [buying, setBuying] = useState(false)

    const updateCountlyConfig = async () => {
        try {
            let result = await requestCountlyConfig();
            let soldUsd = localStorage.getItem('soldUsd')
            if (result.soldUsd) {
                soldUsd = result.soldUsd
            }
            if (soldUsd) {
                if (!saleInfo.saleAmount || saleInfo.saleAmount !== Number(soldUsd)) {
                    saleInfo.saleAmount = Number(soldUsd)
                    saleInfo.saleAmountStr = Number(soldUsd).toLocaleString()


                    if (Number(soldUsd) >= saleInfo.saleGoal) {
                        saleInfo.saleProgress = 100
                        saleInfo.isSoldOut = true
                    } else {
                        saleInfo.isSoldOut = false
                        saleInfo.saleProgress = parseFloat(((Number(soldUsd) / saleInfo.saleGoal) * 100).toFixed(2))
                    }
                    console.log(`saleInfo.saleProgress ${saleInfo.saleProgress}`)
                    saleInfo.saleGoalStr = '1,000,000';
                    setSaleInfo(saleInfo);
                }
            }
        } catch (error) {
            throw error
        }
    };

    const filterNumber = (val: any) => {
        let newval = val.replace(/[^\d.]/g, "");
        newval = newval.replace(/\.{2,}/g, "."); // 不能连续输入两个及以上小数点
        newval = newval.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 只保留第一个".", 清除多余的"."
        return newval
    }


    const startTimer = () => {
        const timer = setInterval(() => {
            if (latestLeaveTime.current === 0) {
                clearInterval(timer);
                return;
            }
            timeState.day = (latestLeaveTime.current !== null ? Math.floor(latestLeaveTime.current / 60 / 60 / 24) : 0)
                .toString()
                .padStart(2, '0');
            timeState.hour = (latestLeaveTime.current ? Math.floor(latestLeaveTime.current / 60 / 60 % 24) : 0)
                .toString()
                .padStart(2, '0');
            timeState.minute = (latestLeaveTime.current ? Math.floor(latestLeaveTime.current / 60 % 60) : 0)
                .toString()
                .padStart(2, '0');
            timeState.second = (latestLeaveTime.current ? Math.floor(latestLeaveTime.current % 60) : 0)
                .toString()
                .padStart(2, '0');
            setLeaveTime(latestLeaveTime.current - 1)
        }, 1000);
    }

    const filterAddress = (address: String) => {
        return address.slice(0, 6) + "..." + address.slice(-4);
    }
    const rate = () => {
        if (selectedCoin.name === 'ETH') {
            return infoData.eth_to_usd
        } else if (selectedCoin.name === 'USDT' || selectedCoin.name === 'USDC') {
            return infoData.usdt_to_usd
        } else {
            return null
        }
    }
    const changeCoinAmount = (val: any) => {
        if (typeof val === 'object') {
            val = val.target.value
            setCoinAmount(val)
        }
        if (!val) {
            setTgbAmount("");
            setCoinAmount("");
            return val;
        }
        let newVal = filterNumber(val)
        setCoinAmount(newVal)
        let rateNum = rate()
        let tgbnum = 0
        if (rateNum) {
            tgbnum = (Number(rateNum ? rateNum : "0") / infoData.tokenPrice) * newVal;

            setTgbAmount(parseInt(tgbnum.toString()))
        }
    }

    const changeTGBAmount = (val: any) => {
        if (typeof val === 'object') {
            val = val.target.value
            setTgbAmount(val)
        }
        if (!val) {
            setCoinAmount("");
            return val;
        }
        let newVal = parseInt(filterNumber(val))
        setTgbAmount(parseInt(newVal.toString()))

        let rateNum = rate()
        if (rateNum) {
            let coinAmount = (newVal * infoData.tokenPrice) / Number(rateNum ? rateNum : "0")
            setCoinAmount(coinAmount)
        }
    }
    const checkTips = () => {
        try {
            let selectCoin = selectedCoin.name

            if (selectCoin === 'USDT') {
                //check usdt approve
                console.log(`check usdtAllowance ${myBalance.usdtAllowance}`)
                if (myBalance.usdtAllowance < 100000000) {
                    setApprove(true);
                } else {
                    setApprove(false);

                }
            } else if (selectCoin === 'USDC') {
                //check usdc approve
                console.log(`check usdcAllowance ${myBalance.usdcAllowance}`)

                if (myBalance.usdcAllowance < 100000000) {
                    setApprove(true);
                } else {
                    setApprove(false);
                }
            }
            else if (selectCoin === 'ETH') {
                setApprove(false);
            }

            let tempTipMsg = ''

            if (!tgbAmount || (Number(tgbAmount) < 100)) {

                tempTipMsg = t('tip.text2');
                setTipMsg(tempTipMsg)
                setNormalMsg("")
                setWalletTipMsg("")
                setApprove(false)
                return
            }

            if (selectCoin === 'ETH') {

                if (Number(myBalance.ethBalance) < (Number(coinAmount) + fee)) {
                    tempTipMsg = t('tip.text3')
                } else {
                    tempTipMsg = ""
                }
            } else if (selectCoin === 'USDT') {
                if (Number(myBalance.ethBalance) < fee) {
                    tempTipMsg = t('tip.text4')
                } else if (Number(coinAmount) > Number(myBalance.usdtBalance)) {
                    tempTipMsg = t('tip.text5')
                } else {
                    tempTipMsg = ""
                }
            } else if (selectCoin === 'USDC') {
                if (Number(myBalance.ethBalance) < fee) {
                    tempTipMsg = (t('tip.text6'))
                } else if (Number(coinAmount) > Number(myBalance.usdcBalance)) {
                    tempTipMsg = (t('tip.text7'))
                } else {
                    tempTipMsg = ""
                }
            }
            let maxAmount = infoData.maxTokensToBuy

            if (Number(tgbAmount) > maxAmount) {
                let max = maxAmount.toLocaleString()
                tempTipMsg = t('tip.text8', { amount: max })
            }

            if (tempTipMsg) {
                setTipMsg(tempTipMsg)
                setNormalMsg("")
                setWalletTipMsg("")
            } else {
                setNormalMsg(t('tip.text9'));
                setWalletTipMsg("");
                setTipMsg("");
            }



        } catch (error) {
            throw error
        }
    }
    const chooseMoney = (value: any) => {
        if (selectedCoin.name === value) { return }
        // @ts-ignore
        const val: any = coinInfo[value]
        setSelectedCoin(val)
        setCoinAmount("")
        setTgbAmount("")

        console.log(`selected ${value}`)
        checkTips()
    };

    const maxClick = () => {
        if (!connected) { return }
        if (!myBalance) { return }

        if (selectedCoin.name === 'ETH') {
            // @ts-ignore
            if (!myBalance.ethBalance || myBalance.ethBalance === '0') {
                changeCoinAmount(0)
                return
            }
            let ethAmount = Number(myBalance.ethBalance) - fee
            if (ethAmount <= 0) {
                changeCoinAmount(0)
                return
            }
            changeCoinAmount(ethAmount.toString())


        } else if (selectedCoin.name === 'USDT') {
            // @ts-ignore
            if (!myBalance.usdtBalance || myBalance.usdtBalance == '0') {
                changeCoinAmount(0)
                return
            }
            changeCoinAmount(myBalance.usdtBalance)
        } else if (selectedCoin.name === 'USDC') {
            // @ts-ignore
            if (!myBalance.usdcBalance || myBalance.usdcBalance == '0') {
                changeCoinAmount(0)
                return
            }
            changeCoinAmount(myBalance.usdcBalance)
        }

    }
    const filterCoinName = () => {
        return selectedCoin.name
    }
    const showDialog = () => {
        setDialogVisible(true)
    }
    const copyHandle = () => {
        copy(infoData.tgbAddress)
    }
    const disconnectWallect = () => {
        addEvent(Countlykeys.disconnect_click, 1)
        disconnect()
    }
    const connectWithWalletConnect = () => {
        addEvent(Countlykeys.connect_click, 1)
        openConnectModal && openConnectModal()
    }
    const getInviteCode = () => {
        let inviteCode = localStorage.getItem('inviteCode') || ''
        let bytesArr = stringToBytes(inviteCode, { size: 32 })
        // @ts-ignore
        return '0x' + Buffer.from(bytesArr, 'utf8').toString('hex');
    }
    const checkEnableBuy = () => {
        try {
            if (!tgbAmount || (Number(tgbAmount) < 100)) {

                return false
            }

            let maxAmount = infoData.maxTokensToBuy
            if (Number(tgbAmount) > maxAmount) {
                return false
            }

            if (infoData.paused) {
                messageApi.open({ type: 'warning', content: t('tip.text10'), });
                return false
            }
            return true
        } catch (error) {
            catchError(error) 
            return false
        }
    }
    // user click buy and staking
    // 1 ETH-BUY
    // 2 ETH-BUY-STAKING
    // 3 USDT-BUY
    // 4 USDT-BUY-STAKING
    // 5 USDC-BUY
    // 6 USDC-BUY-STAKING
    const buyTokenAndStaking = () => {
        setWalletTipMsg('')
        let enableBuy = checkEnableBuy()
        if (!enableBuy) {

            return
        }
        if (selectedCoin.name === 'ETH') {
            startBuyToken(tgbAmount, 1)
        } else if (selectedCoin.name === 'USDT') {
            startBuyToken(tgbAmount, 3)
        } else if (selectedCoin.name === 'USDC') {
            startBuyToken(tgbAmount, 5)
        }
    }

    // user click only buy
    const buyToken = () => {

        setWalletTipMsg('')
        let enableBuy = checkEnableBuy()
        if (!enableBuy) {
            return
        }

        if (selectedCoin.name === 'ETH') {
            startBuyToken(tgbAmount, 2)
        } else if (selectedCoin.name === 'USDT') {
            startBuyToken(tgbAmount, 4)
        } else if (selectedCoin.name === 'USDC') {
            startBuyToken(tgbAmount, 6)
        }
    }

    //ETH 购买
    const startBuyToken = async (amount: any, buyType: any) => {
        try {

            let result = await checkNetwork()
            if (!result) {
                return;
            }
            setBuying(true)

            const inviteCodeParam = getInviteCode();

            let hash = ''
            let proxyContract = getPreSaleContract()
            let usdcContract = getUsdcContract()
            let usdtContract = getUsdtContract()

            let wallectClient = await appWallectClient()


            if (buyType === 1 || buyType === 2) {


                // @ts-ignore
                let ethPayAmount = await appPublicClient().readContract({
                    ...proxyContract,
                    functionName: 'ethBuyHelper',
                    args: [BigInt(amount)]
                })

                // @ts-ignore
                ethPayAmount = formatUnits(Number(ethPayAmount), "18") + fee


                let functionName = buyType === 1 ? "buyWithEthAndStake" : "buyWithEth"
                if (buyType === 1) {
                    addEvent(Countlykeys.buyWithEthAndStake_click, ethPayAmount)
                } else {
                    addEvent(Countlykeys.buyWithEth_click, ethPayAmount)
                }

                // @ts-ignore
                hash = await wallectClient.writeContract({
                    ...proxyContract,
                    functionName: functionName,
                    args: [BigInt(amount), inviteCodeParam],
                    // @ts-ignore
                    value: parseEther(ethPayAmount.toString()),
                    account: account.address
                })


            } else if (buyType === 3 || buyType === 4) {

                let approveResult = false

                let allowanceData = await checkApprove(usdtContract, account.address, proxyContract.address)

                // @ts-ignore
                let usdtAllowance = formatUnits(Number(allowanceData), "6") * 1

                console.log(`usdtAllowanceData ${usdtAllowance} usdtPayAmount:${coinAmount}`)
                // @ts-ignore
                if (Number(usdtAllowance) < Number(coinAmount)) {

                    if (Number(usdtAllowance) === 0) {
                        let approveTx = await approveContract(usdtContract, proxyContract.address, account)
                        if (approveTx) {
                            messageApi.open({
                                type: 'success',
                                content: t('tip.text27'),
                            });
                            let result = await waitTx(approveTx)
                            if (result) {
                                approveResult = true
                                messageApi.open({
                                    type: 'success',
                                    content: t('tip.text28'),
                                });
                            } else {
                                messageApi.open({
                                    type: 'error',
                                    content: t('tip.text29'),
                                });
                            }
                        }
                    } else {
                        //先授权 0
                        console.log(`授权0额度`)
                        let zeroApproveResult = false
                        let approveZeroTx = await approveContractZero(usdtContract, proxyContract.address, account)
                        if (approveZeroTx) {
                            messageApi.open({
                                type: 'success',
                                content: t('tip.text27'),
                            });
                            let result = await waitTx(approveZeroTx)
                            if (result) {
                                zeroApproveResult = true
                                messageApi.open({
                                    type: 'success',
                                    content: t('tip.text28'),
                                });
                            } else {
                                messageApi.open({
                                    type: 'error',
                                    content: t('tip.text29'),
                                });
                            }
                        }
                        //0额度授权失败
                        if (!zeroApproveResult) {
                            setBuying(false)
                            return
                        }
                        console.log(`再次授权额度`)

                        let approveTx = await approveContract(usdtContract, proxyContract.address, account)
                        if (approveTx) {
                            messageApi.open({
                                type: 'success',
                                content: t('tip.text27'),
                            });
                            let result = await waitTx(approveTx)
                            if (result) {
                                approveResult = true
                                messageApi.open({
                                    type: 'success',
                                    content: t('tip.text28'),
                                });
                            } else {
                                messageApi.open({
                                    type: 'error',
                                    content: t('tip.text29'),
                                });
                            }
                        }
                    }
                    if (!approveResult) {
                        setBuying(false)
                        homeInfo()
                        checkTips()
                        return
                    }
                }

                if (buyType === 3) {
                    addEvent(Countlykeys.buyWithUSDTAndStake_click, coinAmount)
                } else {
                    addEvent(Countlykeys.buyWithUSDT_click, coinAmount)
                }
                let functionName = buyType === 3 ? "buyWithUSDTAndStake" : "buyWithUSDT"
                // @ts-ignore
                hash = await wallectClient.writeContract({
                    ...proxyContract,
                    functionName: functionName,
                    args: [BigInt(parseInt(amount)), inviteCodeParam],
                    account: account.address
                })
            }
            else if (buyType === 5 || buyType === 6) {

                let approveResult = false

                let allowanceData = await checkApprove(usdcContract, account.address, proxyContract.address)

                // @ts-ignore
                let usdcAllowance = formatUnits(Number(allowanceData), "6") * 1

                console.log(`usdcAllowanceData ${usdcAllowance} usdcPayAmount:${coinAmount}`)
                // @ts-ignore
                if (Number(usdcAllowance) < Number(coinAmount)) {

                    if (Number(usdcAllowance) === 0) {
                        let approveTx = await approveContract(usdcContract, proxyContract.address, account)
                        if (approveTx) {
                            messageApi.open({
                                type: 'success',
                                content: t('tip.text11'),
                            });
                            let result = await waitTx(approveTx)
                            if (result) {
                                approveResult = true
                                messageApi.open({
                                    type: 'success',
                                    content: t('tip.text12'),
                                });
                            } else {
                                messageApi.open({
                                    type: 'error',
                                    content: t('tip.text13'),
                                });
                            }
                        }
                    } else {
                        //先授权 0
                        console.log(`授权0额度`)
                        let zeroApproveResult = false
                        let approveZeroTx = await approveContractZero(usdcContract, proxyContract.address, account)
                        if (approveZeroTx) {
                            messageApi.open({
                                type: 'success',
                                content: t('tip.text27'),
                            });
                            let result = await waitTx(approveZeroTx)
                            if (result) {
                                zeroApproveResult = true
                                messageApi.open({
                                    type: 'success',
                                    content: t('tip.text28'),
                                });
                            } else {
                                messageApi.open({
                                    type: 'error',
                                    content: t('tip.text29'),
                                });
                            }
                        }
                        if (!zeroApproveResult) {
                            setBuying(false)
                            return
                        }
                        console.log(`再次授权额度`)

                        let approveTx = await approveContract(usdcContract, proxyContract.address, account)
                        if (approveTx) {
                            messageApi.open({
                                type: 'success',
                                content: t('tip.text27'),
                            });
                            let result = await waitTx(approveTx)
                            if (result) {
                                approveResult = true
                                messageApi.open({
                                    type: 'success',
                                    content: t('tip.text28'),
                                });
                            } else {
                                messageApi.open({
                                    type: 'error',
                                    content: t('tip.text29'),
                                });
                            }
                        }
                    }
                    if (!approveResult) {
                        setBuying(false)
                        homeInfo()
                        checkTips()
                        return
                    }
                }
                if (buyType === 5) {
                    addEvent(Countlykeys.buyWithUSDCAndStake_click, coinAmount)
                } else {
                    addEvent(Countlykeys.buyWithUSDC_click, coinAmount)
                }
                let functionName = buyType === 5 ? "buyWithUSDCAndStake" : "buyWithUSDC"
                // @ts-ignore
                hash = await wallectClient.writeContract({
                    ...proxyContract,
                    functionName: functionName,
                    args: [BigInt(parseInt(amount)), inviteCodeParam],
                    account: account.address
                })
            } else {

            }
            if (hash) {
                messageApi.open({
                    type: 'success',
                    content: t('tip.text11'),
                });
                let result = await waitTx(hash)
                if (result) {
                    messageApi.open({
                        type: 'success',
                        content: t('tip.text12'),
                    });
                    homeInfo()
                } else {
                    messageApi.open({
                        type: 'success',
                        content: t('tip.text13'),
                    });
                }
            }
            changeCoinAmount(0)
            setBuying(false)
        } catch (err:any) {
            console.log(err)

            setBuying(false)

            // @ts-ignore
            if (err && err.shortMessage) {
                // @ts-ignore
                if (err.shortMessage === 'User rejected the request.') {
                    // @ts-ignore
                    setWalletTipMsg(err.shortMessage)
                } else {
                    setWalletTipMsg('An unknown RPC error occurred!')
                    catchError({
                        message: err.message || err.shortMessage || '',
                        stack: account.address
                    });
                }
            } 
            throw err
        }
    };

    const checkNetwork = async () => {
        try {
            const currentNetwork = getNetwork()

            if (!currentNetwork || !currentNetwork.chain) {
                messageApi.open({
                    type: 'warning',
                    content: t('tip.text1'),
                });
                return false;
            }
            let currentNetId = currentNetwork.chain.id

            if (currentNetId !== appChain.id) {
                try {
                    const network = await switchNetwork({
                        chainId: appChain.id,
                    })
                    //更新界面
                    if (network.id === appChain.id) {
                        return true
                    } else {
                        return false
                    }
                } catch (error) {
                    messageApi.open({
                        type: 'error',
                        content: t('tip.text30'),
                    });
                    return false
                }
               
            }
            return true
        } catch (error) {
            catchError(error)
            return false            
        }
    }

    const fetchMyBalance = async () => {

        try {
            if (!account || !account.address) {

                return
            }
            const callBalanceOfConfig = {
                abi: erc20ABI,
                functionName: 'balanceOf',
                args: [account.address]
            }

            let proxyContract = getPreSaleContract()
            let usdtContract = getUsdtContract()
            let usdcContract = getUsdcContract()
            const balanceArr = await appPublicClient().multicall({
                contracts: [
                    {
                        // The TGB token
                        address: tgbAddress,
                        ...callBalanceOfConfig
                    },
                    {
                        // The USDT token
                        address: usdtAddress,
                        ...callBalanceOfConfig
                    },
                    {
                        // The USDC token
                        address: usdcAddress,
                        ...callBalanceOfConfig
                    },
                    // @ts-ignore
                    {
                        ...proxyContract,
                        functionName: 'userDeposits',
                        args: [account.address]
                    },
                    // @ts-ignore
                    {
                        ...usdtContract,
                        functionName: "allowance",
                        args: [account.address, proxyContract.address]
                    },
                    // @ts-ignore
                    {
                        ...usdcContract,
                        functionName: "allowance",
                        args: [account.address, proxyContract.address]
                    },
                ],
            })
            let ethBalance = await appPublicClient().getBalance({
                address: account.address,
            })

            let resultData = {
                ethBalance: formatEther(ethBalance),
                // @ts-ignore
                tgbBalance: formatUnits(balanceArr[0].result, '18'),
                // @ts-ignore
                usdtBalance: formatUnits(balanceArr[1].result, '6'),
                // @ts-ignore
                usdcBalance: formatUnits(balanceArr[2].result, '6'),
                // @ts-ignore
                tgbDeposits: formatUnits(balanceArr[3].result, '18'),
                usdtAllowance: balanceArr[4].result,
                usdcAllowance: balanceArr[5].result
            }
            // @ts-ignore
            setMyBalance(resultData)
            console.log(
                `ethBalance:${resultData.ethBalance} ,
            tgbBalance :${resultData.tgbBalance},
            usdtBalance :${resultData.usdtBalance},
            usdcBalance :${resultData.usdcBalance},
            tgbDeposits :${resultData.tgbDeposits},
            usdtAllowance :${resultData.usdtAllowance},
            usdcAllowance :${resultData.usdcAllowance}`)
        } catch (error) {
            throw error
        }
    }

    const homeInfo = async () => {
        if (connected) {
            let result = await checkNetwork()
            if (!result) { return }
        }

        if (account && account.address) {

            await fetchMyBalance()
        }
        // @ts-ignore
        let proxyContract = getPreSaleContract()
        // @ts-ignore
        let stakeContract = getStakeContract()

        const data = await appPublicClient().multicall({

            contracts: [
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'baseDecimals',
                },
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'startTime',
                },
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'endTime',
                },
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'tokenPrice',
                },
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'getLatestPrice',
                },
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'saleToken',
                },
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'totalTokensSold',
                },
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'maxTokensToBuy',
                },
                // @ts-ignore
                {
                    ...proxyContract,
                    functionName: 'paused',
                },
                // @ts-ignore
                {
                    ...stakeContract,
                    functionName: 'tokensStaked',   //总质押Token 数量
                },
                // @ts-ignore
                {
                    ...stakeContract,
                    functionName: 'launchTime',   //质押开始时间
                },
                // @ts-ignore
                {
                    ...stakeContract,
                    functionName: 'endBlock',   //结束区块
                },
                // @ts-ignore
                {
                    ...stakeContract,
                    functionName: 'lastRewardedBlock',  //最新开始获取奖励区块
                },
                // @ts-ignore
                {
                    ...stakeContract,
                    functionName: 'rewardTokensPerBlock',  //每个区块奖励
                }

            ]
        })
        let resultData = {
            baseDecimals: data[0].result,           //[0]
            startTime: data[1].result,              //[1]
            endTime: data[2].result,                //[2]
            tokenPrice: data[3].result,             //[3]
            getLatestPrice: data[4].result,         //[4]
            saleToken: data[5].result,              //[5]
            totalTokensSold: data[6].result,        //[6]
            maxTokensToBuy: data[7].result,         //[7]
            paused: data[8].result,                 //[8]
            tokensStaked: data[9].result,          //[10]
            launchTime: data[10].result,            //[11]
            endBlock: data[11].result,              //[12]
            lastRewardedBlock: data[12].result,     //[13]
            rewardTokensPerBlock: data[13].result    //[14]
        }
        let info = {
            saleToken: resultData.saleToken,
            baseDecimals: null,
            tokenPrice: null,
            saleAmount: null,
            eth_to_usd: null,
            usdt_to_usd: 1,
            maxTokensToBuy: Number(resultData.maxTokensToBuy),
            paused: resultData.paused,
            apy: '',
            startTime: resultData.startTime,
            endTime: resultData.endTime,
            tgbAddress: tgbAddress
        }

        let tgbBalance = await appPublicClient().getBalance({
            // @ts-ignore
            address: stakeContract.address,
            token: resultData.saleToken,
        })

        //最大购买额


        //TGB Price
        // @ts-ignore
        info.baseDecimals = resultData.baseDecimals.toString().length - 1
        // @ts-ignore
        info.tokenPrice = formatUnits(resultData.tokenPrice, info.baseDecimals)


        //ethPrice
        // @ts-ignore
        info.eth_to_usd = formatEther(resultData.getLatestPrice)



        //当前质押总量
        // @ts-ignore
        let totalStake = formatUnits(resultData.tokensStaked, "18")

        //当前质押池剩余TGB总量
        // @ts-ignore
        let totalBalance = formatUnits(tgbBalance, "18")

        let endTime = Number(resultData.endTime)

        //剩余质押天数
        let RemainingStakeDays = (Math.floor(endTime * 1000 - new Date().getTime()) / 1000 / 3600 / 24)

        //剩余奖励

        // @ts-ignore
        const block = await appPublicClient().getBlockNumber()

        // @ts-ignore
        let remainingBlock = resultData.endBlock - block

        // @ts-ignore
        let recordPerBlock = formatUnits(resultData.rewardTokensPerBlock, '18')
        let remainingRecord = Number(recordPerBlock) * Number(remainingBlock)

        //当年年化收益率
        // @ts-ignore
        let apy = (((remainingRecord) / totalStake * (365 / RemainingStakeDays)) * 100).toFixed(1)


        info.apy = (apy && (apy !== 'Infinity')) ? (apy + '%') : ''

        //leaveTime
        // @ts-ignore
        let startTime = Number(resultData.startTime * 1000n)

        let tempTime = (Math.ceil((+new Date() - startTime) / (7 * 864 * 1e5)) * (7 * 864 * 1e5) + startTime) - new Date().getTime();

        latestLeaveTime.current = tempTime / 1000
        setLeaveTime(tempTime / 1000)
        startTimer()
        // @ts-ignore
        setInfoData(info)

        checkTips()

    }

    useInterval(() => {
        updateCountlyConfig()
        fetchMyBalance()
    }, 15000);

    useEffect(() => {
        latestLeaveTime.current = leaveTime;
    }, [leaveTime]);

    useEffect(() => {
        checkTips()
    }, [coinAmount])

    // useEffect(() => {
    //     homeInfo()
    // },[account])
    useEffect(() => {
        checkTips()
    }, [selectedCoin])
    useEffect(() => {
        checkTips()
    }, [myBalance])

    useEffect(() => {
        updateCountlyConfig()
    }, [])
    useEffect(() => {
        homeInfo()
    }, [])

    // @ts-ignore
    return (<>
        {contextHolder}
        <div className="form-wrapper">
            {connected && buying ? <div className="buying-bg">
                <div className="buying-text">{t('homeForm.text15')}</div>
            </div> : ""}

            <Row>
                <div className="grid-content">$ {saleInfo ? saleInfo.saleAmountStr : ""}</div>
            </Row>
            <Row>
                <Col span={12} className="current-col">
                    <p>{t('homeForm.text1')}</p>
                    <p>$ {infoData.tokenPrice}</p>
                </Col>
                <Col span={12} className="current-col">
                    <p>{t('homeForm.text18')}</p>
                    <p>$ {saleInfo ? saleInfo.saleGoalStr : ""}</p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <BgProgress
                        rate={`${saleInfo.saleProgress}%`}
                    >
                    </BgProgress>
                    {/* <Progress percent={saleInfo.saleProgress} showInfo={false} strokeColor="#C5AC79" strokeWidth={28} /> */}
                </Col>
            </Row>
            {
                (!infoData.paused && !saleInfo.isSoldOut) ? <Row v-if="!infoData.paused && !saleInfo.isSoldOut">
                    <Col span={24} className="tips" style={{ textAlign: 'left' }}>{t('homeForm.text3')}</Col>
                </Row> : ""
            }
            {
                (!infoData.paused && !saleInfo.isSoldOut) ? <Row gutter={10} style={{ marginTop: "12px" }}>
                    <Col span={6}><span className="time-btn">{timeState.day ? timeState.day : '00'} D</span></Col>
                    <Col span={6}><span className="time-btn">{timeState.hour ? timeState.hour : '00'} H</span></Col>
                    <Col span={6}><span className="time-btn">{timeState.minute ? timeState.minute : '00'} M</span></Col>
                    <Col span={6}><span className="time-btn">{timeState.second ? timeState.second : '00'} S</span></Col>
                </Row> : ""
            }
            <Line></Line>
            {
                (!infoData.paused && !saleInfo.isSoldOut) ? <>
                    {
                        connected ? <Row style={{ marginTop: "15px" }}>
                            <Col xs={24} sm={24} lg={24}>
                                <div className="wallect">
                                    <div className="logtips">{t('homeForm.text4')} </div>
                                    <div className="lououtContainer">
                                        <p className="logtips" style={{ marginRight: "15px" }}>{filterAddress(account.address ? account.address : '')}</p>
                                        <img className="logout" onClick={disconnectWallect} src="img/logout.png" alt='logout' />
                                    </div>
                                </div>
                            </Col>
                        </Row> : ""
                    }
                    {
                        (connected && myBalance && (myBalance.tgbDeposits) && (myBalance.tgbDeposits !== '0')) ? <Row style={{ marginTop: "15px" }}>
                            <Col xs={24} sm={24} lg={24}>
                                <div className="wallect">
                                    <div className="logtips">$TGB <span style={{ opacity: 0.6, marginLeft: "8px" }}>{t('homeForm.text16')}</span>
                                    </div>
                                    <div style={{ opacity: 0.6 }}>
                                        {Number(myBalance.tgbDeposits).toLocaleString()}
                                    </div>
                                </div>
                            </Col>
                        </Row> : ""
                    }
                    <Row gutter={20} style={{ marginTop: "8px" }}>
                        <Col sm={8} xs={8}>
                            <div className={classnames({ "eth-btn": true, on: selectedCoin.name === 'ETH' })} onClick={() => { chooseMoney('ETH') }}>
                                <img src="img/eth.png" className="icon" alt='eth' />ETH
                            </div>
                        </Col>
                        <Col sm={8} xs={8}>
                            <div className={classnames({ "eth-btn": true, on: selectedCoin.name === 'USDT' })} onClick={() => { chooseMoney('USDT') }}>
                                <img src="img/usdt.png" className="icon" alt='USDT' />USDT
                            </div>
                        </Col>
                        <Col sm={8} xs={8}>
                            <div className={classnames({ "eth-btn": true, on: selectedCoin.name === 'USDC' })} onClick={() => { chooseMoney('USDC') }}>
                                <img src="img/usdc.png" className="icon" alt='USDC' />USDC
                            </div>
                        </Col>
                        <Col xs={24} sm={24} lg={24} style={{ marginTop: "8px" }}>
                            <div className="pay-tips" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div> {filterCoinName()} {t('homeForm.text5')} </div>
                                <div className="max-value" onClick={maxClick}>{t('homeForm.text7')}</div>
                            </div>
                            <Input placeholder="0" className="f-ipt"
                                value={coinAmount}
                                onChange={changeCoinAmount} allowClear
                            ></Input>
                        </Col>
                        <Col xs={24} sm={24} lg={24}>
                            <div className="pay-tips" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <p className="pay-tips">{t('homeForm.text8')}</p>
                            </div>
                            <Input placeholder="0" className="f-ipt" style={{ marginTop: "15px" }} value={tgbAmount} onChange={changeTGBAmount} allowClear></Input>

                        </Col>
                    </Row>
                    {
                        connected ? <Row>
                            {normalMsg ? <div className="normal-message"><p>{normalMsg}</p></div> : ""}
                            {tipMsg ? <div className="tip-message"><p>{tipMsg}</p></div> : ""}
                            {walletTipMsg ? <div className="tip-message"><p>{walletTipMsg}</p></div> : ""}
                        </Row> : ""
                    }
                    <Row>
                        <Col span={24}>
                            {
                                !connected ? <Button className="connect-btn" onClick={connectWithWalletConnect}>
                                    {t('homeForm.text9')}
                                </Button> : ""
                            }
                            {
                                (connected && !approve) ? <Button className="buy-and-stake-btn" onClick={buyTokenAndStaking} disabled={!normalMsg}>
                                    {t('homeForm.text10', { APY: infoData.apy })}
                                </Button> : ""
                            }
                            {
                                (connected && approve) ? <div className="approve">
                                    <Button className="buy-and-stake-btn" onClick={buyTokenAndStaking} disabled={!normalMsg}>
                                        {t('tip.text20')}
                                    </Button>
                                    <div className="approve-tip">{t('tip.text19')}</div>
                                </div> : ""
                            }
                            {
                                (connected && !approve) ? <div className="stake-buy-btn-container">
                                    <Button className="stake-buy-btn" type="text" onClick={buyToken} disabled={!normalMsg}>
                                        <p>{t('homeForm.text11')}</p>
                                    </Button>
                                </div> : ""
                            }
                        </Col>
                        {
                            (!connected) ? <Col span="24" className="gray-tips">{t('homeForm.text12')} : {infoData.apy}</Col> : ""
                        }
                        <Col span={24} style={{ marginTop: "12px" }}>
                            <Button className="buy-and-stake-btn" onClick={showDialog}>{t('tip.text23')}</Button>
                        </Col>
                    </Row>
                </> : ""
            }
            {
                (infoData.paused || saleInfo.isSoldOut) ? <Row className="sold-out-container">
                    <div className="sold-out-tip">{t('homeForm.text17')}</div>
                </Row> : ""
            }
            <Modal visible={dialogVisible} title={t('tip.text23') + "(ERC20)"} width="520px" onCancel={() => { setDialogVisible(false) }} footer={null} style={{ minHeight: "100px" }}>
                <div style={{ marginBottom: "20px" }}> {infoData.tgbAddress}</div>
                <Button className="buy-and-stake-btn copy" onClick={copyHandle}>{t('tip.text24')}</Button>
            </Modal>
        </div>
    </>)
}

