import '../assets/style/modal.css'
const DialogCookie = () => {
    return(
        <div className="modal-body">
            <div><strong>1. Introduction</strong>
                <div>Welcome to TG.Bet's Cookie Policy. This policy provides detailed information about how and when we use
                    cookies on our website. By continuing to browse our site, you consent to the use of these cookies in accordance
                    with this policy. As our website is based in Curaçao, our cookie practices are designed to comply with the
                    relevant regulations applicable within the jurisdiction.
                </div>
                <strong>2. What is a Cookie?</strong>
                <div>A cookie is a small piece of data that a website asks your browser to store on your computer or mobile
                    device. It enables the website to remember your actions and preferences (such as login, language, font size, and
                    other display preferences) over a period of time, so you don't have to keep re-entering them whenever you come
                    back to the site or browse from one page to another.
                </div>
                <strong>3. How We Use Cookies</strong>
                <div>We use cookies on our website:</div>
                <ul>
                    <li><strong>3.1. Session Cookies:</strong> These are temporary cookies that remain only until you close your
                        browser. They help our website recognize you as you move between pages.
                        <li><strong>3.2. Persistent Cookies:</strong> These remain on your device until they expire or you delete
                            them. They help us recognize you when you return to our website and remember your preferences.
                        </li>
                        <li><strong>3.3. Third-Party Cookies:</strong> We may also use third-party cookies to help us with market
                            research, tracking revenue, improving site functionality, and monitoring compliance with our terms and
                            conditions and copyright policy.
                        </li>
                    </li>
                </ul>
                <strong>4. Your Choices</strong>
                <div>You have the ability to accept or decline cookies. Most browsers automatically accept cookies, but you can
                    modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be
                    able to fully experience the interactive features of our website.
                </div>
                <strong>5. Cookie Management</strong>
                <div>If you want to delete cookies from your device at any time, you can use the settings of your web browser. You
                    can also set your browser to block cookies or to warn you before a cookie is stored.
                </div>
                <strong>6. Changes to this Policy</strong>
                <div>From time to time, we may update our Cookie Policy to reflect changes to our cookie practices. If we make any
                    substantial changes, we will notify you by posting a prominent announcement on our website.
                </div>
                <strong>7. Contact Us</strong>
                <div>If you have questions or concerns about this Cookie Policy, please contact us at contact@TG.Bet or at:
                </div>
                <i>TG.Bet, MIBS NV, Zuikertuintjeweg Z/N, (Zuikertuin Tower), Curaçao</i></div>
        </div>
    )
}
export default DialogCookie;
