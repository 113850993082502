import { useTranslation } from 'react-i18next';
import '../assets/style/HowToBuy.scss'
export  default ()=> {
    const { t } = useTranslation();
    const steps =  [{ title: t('howToBuy.text3'), desc: t('howToBuy.text4') },
        { title: t('howToBuy.text5'), desc: t('howToBuy.text6') },
        { title: t('howToBuy.text7'), desc: t('howToBuy.text8') },
        { title: t('howToBuy.text9'), desc: t('howToBuy.text10') },
        { title: t('howToBuy.text11'), desc: t('howToBuy.text12') },
    ]
    return(
        <div className="howtobuyContainer">
            <div className="howtoBuyTop">
                <div className="howtobuyLogo"> { t('blockTitle.howBuy1') }</div>
                <div className="desc">
                    { t('howToBuy.text1')}
                </div>
            </div>
            <div className="step">
                <div className="stepTopTitle">{ t('howToBuy.text2')}</div>
                {
                    steps.map((item:any, index:number) =>
                        <div key={index} className="stepItem">
                            <p className="stepTitle">{ item.title }</p>
                            <p className="stepDesc">{ item.desc}</p>
                        </div>)
                }

            </div>
        </div>
    )
}
