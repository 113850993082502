import {Row, Col} from 'antd'
import Line from './Line'
import {useTranslation} from "react-i18next";
import '../assets/style/CoinChance.scss'

export default () => {
    const { t } = useTranslation();

    return (
        <div className="CoinChanceContainer">
            <Row style={{width: "100%", display: "flex", alignItems: "center"}} gutter={30}>
                <Col xs={24} sm={24} lg={13}>
                <div className="coinChanceLogo">
                    { t('blockTitle.cc1') }
                    <Line/>
                </div>
                <div className="desc">{ t('coinChance.text1') }</div>
                <div className="desc">{ t('coinChance.text2') }</div>
                </Col>
                <Col lg={11}>
                    <img className="coinChanceDetailImgae" src="img/coinChanceDetail.png" alt='coinChanceDetailImgae'/>
                </Col>
            </Row>
        </div>
    )
}
