import React from 'react';
import '../assets/style/BgProgress.scss'

interface BgProgressProps extends React.ComponentProps<any> {
    rate: string;              
}

/**
 * 背景进度条
 * @param props
 * @constructor
 */
const BgProgress = (props: BgProgressProps) => {
    const { rate, children } = props;
    return (
        <div className={'container'}>
            <div className={'bit-cellprogress'}>
                <div
                    className={'bit-bar'}
                    style={{ width: `${rate}` }}
                >
                    <div className={'bar-text'}>{`${rate}`}</div>
                </div>
                {children}
            </div>
        </div>
    )
}


export default BgProgress;